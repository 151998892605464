import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "main", "overlay"]
  static values = {
    mobileBreakpoint: { type: Number, default: 768 }
  }

  connect() {
    this.isOpen = true
    this.boundCheckWindowSize = this.checkWindowSize.bind(this)
    this.boundHandleMainClick = this.handleMainClick.bind(this)
    
    window.addEventListener('resize', this.boundCheckWindowSize)
    this.mainTarget.addEventListener('click', this.boundHandleMainClick)
    
    this.checkWindowSize()
  }

  disconnect() {
    window.removeEventListener('resize', this.boundCheckWindowSize)
    this.mainTarget.removeEventListener('click', this.boundHandleMainClick)
  }

  isMobileView() {
    return window.innerWidth <= this.mobileBreakpointValue
  }

  handleMainClick() {
    if (this.isMobileView() && this.isOpen) {
      this.closeSidebar()
    }
  }

  handleOverlayClick() {
    if (this.isMobileView() && this.isOpen) {
      this.closeSidebar()
    }
  }

  toggle() {
    this.isOpen = !this.isOpen
    
    if (this.isMobileView()) {
      this.toggleMobileView()
    } else {
      this.toggleDesktopView()
    }
  }

  toggleMobileView() {
    this.sidebarTarget.classList.toggle("active")
    this.overlayTarget.classList.toggle("active")
  }

  toggleDesktopView() {
    this.sidebarTarget.classList.toggle("sidebar-hidden")
    this.mainTarget.classList.toggle("expanded")
  }

  closeSidebar() {
    this.isOpen = false
    if (this.isMobileView()) {
      this.sidebarTarget.classList.remove("active")
      this.overlayTarget.classList.remove("active")
    }
  }

  checkWindowSize() {
    if (this.isMobileView()) {
      this.configureMobileView()
    } else {
      this.configureDesktopView()
    }
  }

  configureMobileView() {
    this.sidebarTarget.classList.remove("sidebar-hidden")
    this.mainTarget.classList.remove("expanded")
    
    if (!this.isOpen) {
      this.sidebarTarget.classList.remove("active")
      this.overlayTarget.classList.remove("active")
    }
  }

  configureDesktopView() {
    this.sidebarTarget.classList.remove("active")
    this.overlayTarget.classList.remove("active")
    
    if (!this.isOpen) {
      this.sidebarTarget.classList.add("sidebar-hidden")
      this.mainTarget.classList.add("expanded")
    }
  }
}